import React from 'react'
import { VscFilePdf, VscFileZip, VscFile } from 'react-icons/vsc'

const BYTE = 1000
const getKB = (bytes) => Math.round(bytes / BYTE)

const FileIcon = ({ children }) => {
    return <span className="text-4xl">{children}</span>
}

const FileItem = (props) => {
    const { file, children, customise, datatable } = props
    const { type, name, display_name, size, url } = file

    const renderThumbnail = () => {
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp'];
        const zipExtensions = ['zip'];
        const pdfExtensions = ['pdf'];

        const getFileExtension = () => {
            const parts = display_name?.split('.');
            return parts?.length > 1 ? parts[parts.length - 1] : '';
        };

        const fileExtension = getFileExtension();

        const isImageFile = type?.split('/')[0] === 'image' || imageExtensions.includes(fileExtension?.toLowerCase())
        const isZipFile = type === 'application/zip' || zipExtensions.includes(fileExtension?.toLowerCase())
        const isPdfFile = type === 'application/pdf' || pdfExtensions.includes(fileExtension?.toLowerCase())

        if (isImageFile) {
            return (
                <img
                    className="upload-file-image"
                    src={url ? url : URL.createObjectURL(file)}
                    alt={`file preview ${name}`}
                />
            )
        }

        if (isZipFile) {
            return (
                <FileIcon>
                    <VscFileZip />
                </FileIcon>
            )
        }

        if (isPdfFile) {
            return (
                <FileIcon>
                    <VscFilePdf />
                </FileIcon>
            )
        }

        return (
            <FileIcon>
                <VscFile />
            </FileIcon>
        )
    }

    return (
        <div className={`${datatable ? 'datatable-file' :  "upload-file" } ${customise && !datatable ? 'max-w-[300px]' : ''}`}>
            <div className="flex">
                {!customise ? <div className="upload-file-thumbnail">{renderThumbnail()}</div> : ''}
                <div className="upload-file-info">
                    <h6 className={!customise ? `upload-file-name ${url ? 'two-line --break-word' : 'one-line'}` : "text-sm"}>{name || display_name}</h6>
                    {size && <span className={!customise ? "upload-file-size" : "text-xs mt-1"}>{getKB(size)} kb</span>}
                </div>
            </div>
            {children}
        </div>
    )
}

export default FileItem
