import http from "./http.service"

async function getServicePackageList(payload) {
    http.setToken(localStorage.getItem("token"));

    let url = `${process.env.REACT_APP_API_URL}/product?service=${payload.service}&length=${payload.length}&start=${payload.start}&search=${payload.search}&is_package=${true}`;
    
    if (payload.body_type && payload.body_type !== '') {
        url += `&body_type=${payload.body_type}`;
    }
    
    const response = await http.get(url);
    return response.data;
}

// async function getServicePackageList(payload, options = {}) {
//     http.setToken(localStorage.getItem("token"));

//     let url = `${process.env.REACT_APP_API_URL}/product?service=${payload.service}&length=${payload.length}&start=${payload.start}&search=${payload.search}&is_package=${true}`;
    
//     if (payload.body_type && payload.body_type !== '') {
//         url += `&body_type=${payload.body_type}`;
//     }

//     const response = await http.get(url, {
//       signal: options.signal,
//     });
  
//     return response.data;
// }

async function getServiceAlacarteList(payload) {
    http.setToken(localStorage.getItem("token"));

    let url = `${process.env.REACT_APP_API_URL}/product?service=${payload.service}&length=${payload.length}&start=${payload.start}&search=${payload.search}`;

    if (!payload.hasOwnProperty('is_package')) {
        url += `&is_package=false`;
    }

    if (payload.body_type && payload.body_type !== '') {
        url += `&body_type=${payload.body_type}`;
    }

    const response = await http.get(url);
    return response.data;
}

async function createService(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.post(
        process.env.REACT_APP_API_URL + `/admin/product`, payload
    );
    return response.data;
}

async function getServiceInfo(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.get(
        process.env.REACT_APP_API_URL + `/product/${payload.id}`, payload
    );
    return response.data;
}

async function updateService(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.post(
        process.env.REACT_APP_API_URL + `/admin/product/${payload.id}`, payload.data
    );
    return response.data;
}

async function deleteService(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.delete(
        process.env.REACT_APP_API_URL + `/admin/product/${payload.id}`, payload.data
    );
    return response.data;
}

async function toggleService(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.patch(
        process.env.REACT_APP_API_URL + `/admin/product/${payload.id}/toggle`, payload
    );
    return response.data;
}

async function getCategoryList(payload) {
    http.setToken(localStorage.getItem("token"));

    let url = `${process.env.REACT_APP_API_URL}/category?length=${payload.length}&start=${payload.start}&search=${payload.search}`;

    if (payload.hasOwnProperty('status')) {
        url += `&status=${payload.status}`;
    }

    const response = await http.get(url);
    return response.data;
}

async function createCategory(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.post(
        process.env.REACT_APP_API_URL + `/admin/category`, payload
    );
    return response.data;
}

async function getCategoryInfo(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.get(
        process.env.REACT_APP_API_URL + `/category/${payload.id}`, payload
    );
    return response.data;
}

async function updateCategory(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.put(
        process.env.REACT_APP_API_URL + `/admin/category/${payload.id}`, payload
    );
    return response.data;
}

async function deleteCategory(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.delete(
        process.env.REACT_APP_API_URL + `/category/${payload.id}`, payload
    );
    return response.data;
}

async function toggleCategory(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.patch(
        process.env.REACT_APP_API_URL + `/admin/category/${payload.id}/toggle`, payload
    );
    return response.data;
}

async function getBrandList(payload) {
    http.setToken(localStorage.getItem("token"));
    let url = `${process.env.REACT_APP_API_URL}/brand?length=${payload.length}&start=${payload.start}&search=${payload.search}`;

    if (payload.hasOwnProperty('status')) {
        url += `&status=${payload.status}`;
    }

    const response = await http.get(url);
    return response.data;
}

async function createBrand(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.post(
        process.env.REACT_APP_API_URL + `/admin/brand`, payload
    );
    return response.data;
}

async function getBrandInfo(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.get(
        process.env.REACT_APP_API_URL + `/brand/${payload.id}`, payload
    );
    return response.data;
}

async function updateBrand(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.put(
        process.env.REACT_APP_API_URL + `/admin/brand/${payload.id}`, payload
    );
    return response.data;
}

async function deleteBrand(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.delete(
        process.env.REACT_APP_API_URL + `/brand/${payload.id}`, payload
    );
    return response.data;
}

async function toggleBrand(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.patch(
        process.env.REACT_APP_API_URL + `/admin/brand/${payload.id}/toggle`, payload
    );
    return response.data;
}

async function getModelList(payload) {
    http.setToken(localStorage.getItem("token"));
    let url = `${process.env.REACT_APP_API_URL}/model?length=${payload.length}&start=${payload.start}&search=${payload.search}`;

    if (payload.hasOwnProperty('status')) {
        url += `&status=${payload.status}`;
    }

    const response = await http.get(url);
    return response.data;
}

async function createModel(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.post(
        process.env.REACT_APP_API_URL + `/admin/model`, payload
    );
    return response.data;
}

async function getModelInfo(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.get(
        process.env.REACT_APP_API_URL + `/model/${payload.id}`, payload
    );
    return response.data;
}

async function updateModel(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.put(
        process.env.REACT_APP_API_URL + `/admin/model/${payload.id}`, payload
    );
    return response.data;
}

async function deleteModel(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.delete(
        process.env.REACT_APP_API_URL + `/model/${payload.id}`, payload
    );
    return response.data;
}

async function toggleModel(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.patch(
        process.env.REACT_APP_API_URL + `/admin/model/${payload.id}/toggle`, payload
    );
    return response.data;
}

async function getInstallerList(payload) {
    http.setToken(localStorage.getItem("token"));
    let url = `${process.env.REACT_APP_API_URL}/admin/installers?length=${payload.length}&start=${payload.start}&search=${payload.search}`;

    if (payload.hasOwnProperty('status')) {
        url += `&status=${payload.status}`;
    }

    const response = await http.get(url);
    return response.data;
}

async function createInstaller(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.post(
        process.env.REACT_APP_API_URL + `/admin/installers`, payload
    );
    return response.data;
}

async function getInstallerInfo(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.get(
        process.env.REACT_APP_API_URL + `/admin/installers/${payload.id}`, payload
    );
    return response.data;
}

async function updateInstaller(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.put(
        process.env.REACT_APP_API_URL + `/admin/installers/${payload.id}`, payload
    );
    return response.data;
}

async function deleteInstaller(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.delete(
        process.env.REACT_APP_API_URL + `/admin/installers/${payload.id}`, payload
    );
    return response.data;
}

async function getInstallationList(payload) {
    http.setToken(localStorage.getItem("token"));
    let url = `${process.env.REACT_APP_API_URL}/admin/service_installations?length=${payload.length}&start=${payload.start}&search=${payload.search}`;

    if (payload.hasOwnProperty('status')) {
        url += `&status=${payload.status}`;
    }

    const response = await http.get(url);
    return response.data;
}

async function createInstallation(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.post(
        process.env.REACT_APP_API_URL + `/admin/service_installations`, payload
    );
    return response.data;
}

async function getInstallationInfo(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.get(
        process.env.REACT_APP_API_URL + `/admin/service_installations/${payload.id}`, payload
    );
    return response.data;
}

async function updateInstallation(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.put(
        process.env.REACT_APP_API_URL + `/admin/service_installations/${payload.id}`, payload
    );
    return response.data;
}

async function deleteInstallation(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.delete(
        process.env.REACT_APP_API_URL + `/admin/service_installations/${payload.id}`, payload
    );
    return response.data;
}

async function getWindscreenList(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.get(
        process.env.REACT_APP_API_URL + `/windscreen-claims?length=${payload.length}&start=${payload.start}&search=${payload.search}`, payload
    );
    return response.data;
}

async function updateWindscreen(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.put(
        process.env.REACT_APP_API_URL + `/admin/windscreen-claims/${payload.id}`, payload
    );
    return response.data;
}

async function getInsuranceList(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.get(
        process.env.REACT_APP_API_URL + `/insurance-renewals?length=${payload.length}&start=${payload.start}&search=${payload.search}`, payload
    );
    return response.data;
}

async function updateInsurance(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.put(
        process.env.REACT_APP_API_URL + `/admin/insurance-renewals/${payload.id}`, payload
    );
    return response.data;
}

async function getUserList(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.get(
        process.env.REACT_APP_API_URL + `/admin/user?type=${payload.type}&length=${payload.length}&start=${payload.start}&search=${payload.search}`, payload
    );
    return response.data;
}

async function createUser(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.post(
        process.env.REACT_APP_API_URL + `/admin/user`, payload
    );
    return response.data;
}

async function getUserInfo(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.get(
        process.env.REACT_APP_API_URL + `/profile?user_id=${payload.id}`, payload
    );
    return response.data;
}

async function updateUser(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.put(
        process.env.REACT_APP_API_URL + `/admin/user/${payload.id}`, payload
    );
    return response.data;
}

async function getRoleList(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.get(
        process.env.REACT_APP_API_URL + `/admin/roles`, payload
    );
    return response.data;
}

async function updatePassword(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.post(
        process.env.REACT_APP_API_URL + `/admin/change_password`, payload
    );
    return response.data;
}

async function getProductList(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.get(
        process.env.REACT_APP_API_URL + `/user_products?service=${payload.service}`, payload
    );
    return response.data;
}

async function getCartList(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.get(
        process.env.REACT_APP_API_URL + `/cart`, payload
    );
    return response.data;
}

async function addCart(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.post(
        process.env.REACT_APP_API_URL + `/cart/items`, payload
    );
    return response.data;
}

async function updateCart(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.put(
        process.env.REACT_APP_API_URL + `/cart/items/${payload.id}`, payload
    );
    return response.data;
}

async function removeCart(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.delete(
        process.env.REACT_APP_API_URL + `/cart/items/${payload.id}`, payload
    );
    return response.data;
}

async function createOrder(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.post(
        process.env.REACT_APP_API_URL + `/orders`, payload
    );
    return response.data;
}

async function getOrderReport(payload) {
    http.setToken(localStorage.getItem("token"));
    let url = `${process.env.REACT_APP_API_URL}/orders?length=${payload.length}&start=${payload.start}&search=${payload.search}`;

    const response = await http.get(url);
    return response.data;
}

async function getNewOrderCount(payload) {
    http.setToken(localStorage.getItem("token"));
    let url = `${process.env.REACT_APP_API_URL}/admin/pending_order_count`;

    const response = await http.get(url);
    return response.data;
}

async function getCommissionReport(payload) {
    http.setToken(localStorage.getItem("token"));
    let url = `${process.env.REACT_APP_API_URL}/reports/commission_report?length=${payload.length}&start=${payload.start}&search=${payload.search}`;

    if (payload.hasOwnProperty('type')) {
        url += `&type=${payload.type}`;
    }

    if (payload.hasOwnProperty('payment_slip')) {
        url += `&payment_slip=${payload.payment_slip}`;
    }

    const response = await http.get(url);
    return response.data;
}

async function getInstallerCommissionReport(payload) {
    http.setToken(localStorage.getItem("token"));
    let url = `${process.env.REACT_APP_API_URL}/reports/installer_commission_report?length=${payload.length}&start=${payload.start}&search=${payload.search}`;

    if (payload.hasOwnProperty('type')) {
        url += `&type=${payload.type}`;
    }

    if (payload.hasOwnProperty('payment_slip')) {
        url += `&payment_slip=${payload.payment_slip}`;
    }

    const response = await http.get(url);
    return response.data;
}

async function getOrderDetails(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.get(
        process.env.REACT_APP_API_URL + `/orders/${payload.id}`, payload
    );
    return response.data;
}

async function updateOrderReport(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.post(
        process.env.REACT_APP_API_URL + `/admin/orders/${payload.id}`, payload.data
    );
    return response.data;
}

async function getJobList(payload) {
    http.setToken(localStorage.getItem("token"));
    let url = `${process.env.REACT_APP_API_URL}/admin/jobsheets?length=${payload.length}&start=${payload.start}&search=${payload.search}`;

    if (payload.status) {
        url += `&status=${payload.status}`;
    }

    const response = await http.get(url);
    return response.data;
}

async function createJob(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.post(
        process.env.REACT_APP_API_URL + `/admin/jobsheets/create`, payload
    );
    return response.data;
}

async function getJobInfo(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.get(
        process.env.REACT_APP_API_URL + `/admin/jobsheets/view/${payload.id}`, payload
    );
    return response.data;
}

async function updateJob(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.post(
        process.env.REACT_APP_API_URL + `/admin/jobsheets/${payload.id}/update`, payload.data
    );
    return response.data;
}

async function getOrderOption(payload) {
    http.setToken(localStorage.getItem("token"));
    let url = `${process.env.REACT_APP_API_URL}/admin/jobsheets/orders-without-jobsheets`;

    if (payload.hasOwnProperty('search')) {
        url += `?search=${payload.search}`;
    }

    const response = await http.get(url);
    return response.data;
}

async function deleteMedia(payload) {
    http.setToken(localStorage.getItem("token"));
    const response = await http.delete(
        process.env.REACT_APP_API_URL + `/media/${payload.id}`
    );
    return response.data;
}

const apiService = {
    getServicePackageList,
    getServiceAlacarteList,
    createService,
    getServiceInfo,
    updateService,
    deleteService,
    toggleService,
    getCategoryList,
    createCategory,
    getCategoryInfo,
    updateCategory,
    deleteCategory,
    toggleCategory,
    getBrandList,
    createBrand,
    getBrandInfo,
    updateBrand,
    deleteBrand,
    toggleBrand,
    getModelList,
    createModel,
    getModelInfo,
    updateModel,
    deleteModel,
    toggleModel,
    getInstallerList,
    createInstaller,
    getInstallerInfo,
    updateInstaller,
    deleteInstaller,
    getInstallationList,
    createInstallation,
    getInstallationInfo,
    updateInstallation,
    deleteInstallation,
    getWindscreenList,
    updateWindscreen,
    getInsuranceList,
    updateInsurance,
    getUserList,
    getUserInfo,
    updateUser,
    createUser,
    getRoleList,
    updatePassword,
    getProductList,
    getCartList,
    addCart,
    updateCart,
    removeCart,
    createOrder,
    getOrderReport,
    getNewOrderCount,
    getCommissionReport,
    getInstallerCommissionReport,
    getOrderDetails,
    updateOrderReport,
    getJobList,
    createJob,
    getJobInfo,
    updateJob,
    getOrderOption,
    deleteMedia
};

export default apiService;